define("discourse/plugins/discourse-solved/discourse/templates/connectors/user-summary-stat/solved-count", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="userActivity.solved">
    <UserStat
      @value={{model.solved_count}}
      @label="solved.solution_summary"
      @icon="check-square"
    />
  </LinkTo>
  */
  {
    "id": "d51hQm2i",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"userActivity.solved\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@label\",\"@icon\"],[[30,0,[\"model\",\"solved_count\"]],\"solved.solution_summary\",\"check-square\"]],null],[1,\"\\n\"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-solved/discourse/templates/connectors/user-summary-stat/solved-count.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"link-to\",\"user-stat\"]]",
    "moduleName": "discourse/plugins/discourse-solved/discourse/templates/connectors/user-summary-stat/solved-count.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});